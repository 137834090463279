import React from "react";
import GalleryAlbum from "../components/gallery/album";


function AlbumPage() {
    return (
        <div>
            <GalleryAlbum  />


        </div>

    )
        ;
}
export default AlbumPage;