import React from "react";
import Staff from "../components/staff/staff"


function StaffPage() {
    return (
        <div>
            <Staff/>
        </div>

    )
        ;
}
export default StaffPage;