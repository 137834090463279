import React from "react";
import Gallery from "../components/gallery/gallery"


function GalleryPage() {
    return (
        <div>
            <Gallery  />


        </div>

    )
        ;
}
export default GalleryPage;