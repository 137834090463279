import React from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import './articles.css';

function ArticleDetails({ newsData }) {
    const { articleId } = useParams();
    const article = newsData.find((newsItem) => newsItem.id === articleId);

    if (!article) {
        return <div>Article not found</div>;
    }

    return (
        <div className="news-page">
            <div className="title">
                <h2>Article Details</h2>
            </div>
            <div className="news-container">
                <div className="row">
                    <div className="col-md-8">
                        <h2>{article.title}</h2>
                        <h6>
                            {article.section}{' '}
                            <FontAwesomeIcon
                                icon={faNewspaper}
                                style={{ color: '#9e9e9e' }}
                            />
                        </h6>
                        <h6>{article.date}</h6>
                        <p>{article.content}</p>
                    </div>
                    <div className="col-md-4">
                        <div className="images-container">
                            {article.images.map((image, index) => (
                                <div className="img-parent" key={index}>
                                    <img src={image.path} alt={`Image ${index}`} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ArticleDetails;