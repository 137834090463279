import React from "react";
import AboutusPage from "../components/about_us_page/aboutus_page"


function AboutPage() {
    return (
        <div>
           <AboutusPage/>
        </div>

    )
        ;
}
export default AboutPage;