import React from "react";
import Contact from "../components/contact/contact";


function ContactPage() {
    return (
        <div>
            <Contact  />


        </div>

    )
        ;
}
export default ContactPage;