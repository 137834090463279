import React from "react";
import "../assets/css/loader.css"
function Loader(){
    return(
        <div className="loader-parent">
            <div className="loader"></div>
        </div>

    )
}
export default Loader;