import React, {useEffect, useState} from "react";
import "./aboutus_page.css";
import axios from "axios";
import logo from "./logocircle.png"
import Container from "react-bootstrap/Container";

function AboutUsPage() {

    const [utilities, setUtilities] = useState([]);

    useEffect(() => {
        axios
            .get("https://back.creators-foundation.org/api/utilities.php?operation=utilities")
            .then(response => {
                setUtilities(response.data.utilities);
            })
            .catch(error => {
                console.error("Error fetching utilities data:", error);
            });

    }, []);

    return (
        <Container>
            <div className="about-us-container">
                <div className="about-text">
                    <h1>عن المؤسسة</h1>
                    <hr/>
                    <div className="aboutus_text">
                        {utilities
                            .filter(utility => utility.property.includes("logo"))
                            .map(utility => (
                                <div key={utility.property}>

                                    <h2>{utility.value}</h2>

                                    {utility.extra_information &&
                                        <p>{utility.extra_information.replace(/\r\n/g, <br/>)}</p>
                                    }

                                </div>
                            ))
                        }
                        <div>
                            <img src={logo} className="logo-about" alt="Logo"/>
                        </div>
                    </div>

                    <div className="aboutus_text">
                        {utilities
                            .filter(utility => utility.property.includes("means"))
                            .map(utility => {

                                const extraInfoLines = utility.extra_information.split('\r\n');

                                return (
                                    <div  key={utility.property}>

                                        <h2>{utility.value}</h2>

                                        {extraInfoLines.map(line => (
                                            <p>{line}</p>
                                        ))}

                                    </div>
                                );
                            })}
                    </div>
                    <div className="aboutus_text">
                        {utilities
                            .filter(utility => utility.property.includes("goals"))
                            .map(utility => {

                                const extraInfoLines = utility.extra_information.split('\r\n');

                                return (
                                    <div  key={utility.property}>

                                        <h2>{utility.value}</h2>

                                        {extraInfoLines.map(line => (
                                            <p>{line}</p>
                                        ))}

                                    </div>
                                );
                            })}
                    </div>
                    <div className="aboutus_text">
                        {utilities
                            .filter(utility => utility.property.includes("means"))
                            .map(utility => {

                                const extraInfoLines = utility.extra_information.split('\r\n');

                                return (
                                    <div  key={utility.property}>

                                        <h2>{utility.value}</h2>

                                        {extraInfoLines.map(line => (
                                            <p >{line}</p>
                                        ))}

                                    </div>
                                );
                            })}
                    </div>
                    <div className="aboutus_text">
                        {utilities
                            .filter(utility => utility.property.includes("conditions"))
                            .map(utility => {

                                const extraInfoLines = utility.extra_information.split('\r\n');

                                return (
                                    <div key={utility.property}>

                                        <h2>{utility.value}</h2>

                                        {extraInfoLines.map(line => (
                                            <p >{line}</p>
                                        ))}

                                    </div>
                                );
                            })}
                    </div>
                    <div className="aboutus_text">
                        {utilities
                            .filter(utility => utility.property.includes("requierments"))
                            .map(utility => {

                                const extraInfoLines = utility.extra_information.split('\r\n');

                                return (
                                    <div key={utility.property}>

                                        <h2>{utility.value}</h2>

                                        {extraInfoLines.map(line => (
                                            <p >{line}</p>
                                        ))}

                                    </div>
                                );
                            })}
                    </div>
                    <div className="aboutus_text">
                        {utilities
                            .filter(utility => utility.property.includes("rights"))
                            .map(utility => {

                                const extraInfoLines = utility.extra_information.split('\r\n');

                                return (
                                    <div  key={utility.property}>

                                        <h2>{utility.value}</h2>

                                        {extraInfoLines.map(line => (
                                            <p >{line}</p>
                                        ))}

                                    </div>
                                );
                            })}
                    </div>
                    <div className="aboutus_text">
                        {utilities
                            .filter(utility => utility.property.includes("branches"))
                            .map(utility => {

                                const extraInfoLines = utility.extra_information.split('\r\n');

                                return (
                                    <div  key={utility.property}>

                                        <h2>{utility.value}</h2>

                                        {extraInfoLines.map(line => (
                                            <p>{line}</p>
                                        ))}

                                    </div>
                                );
                            })}
                    </div>
                </div>
                <br/>


            </div>
        </Container>
    );

}

export default AboutUsPage;
